/* Portfolio.css */

/* Reset margin and padding for the whole document */
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-image: url("bg.jpg"); /* Relative path */
    background-size: cover; /* Make the background cover the entire area */
    background-repeat: no-repeat; /* Prevent repeating */
    background-position: center; /* Center the background image */
    overflow-x: hidden;
    scroll-behavior:smooth;
    scrollbar-color: #03b15a rgba(255, 255, 255, 0.1); /* Thumb and track */
    scrollbar-width: thin; /* Width of scrollbar for Firefox */

}

/* Navbar Styles */
.navbar {
    background-color: #001f3f00; /* Transparent background */
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%; /* Full height of the screen */
    width: 200px; /* Set a fixed width for the vertical navbar */
    display: flex;
    flex-direction: column; /* Align the navbar items vertically */
    justify-content: center; /* Center the navbar items vertically */
    align-items: flex-start; /* Align items to the left */
}

/* Navbar List */
.nav-list {
    list-style: none;
    display: flex;
    flex-direction: column; /* Make the list items vertical */
    margin: 0;
    padding: 0;
    width: 100%; /* Ensure the list takes full width of navbar */
}

.nav-list li {
    margin-bottom: 15px; /* Add spacing between the items */
    width: 100%; /* Make list items take full width */
}

.nav-list a {
    color: #ffffff;
    font-weight: bold;
    font-size: 1.5rem;
    text-decoration: none;
    padding: 10px 20px;
    display: block;
    position: relative;
    transition: color 0.2s ease-in-out, transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Elastic effect */
    border-left: 4px solid transparent; /* Default border (transparent) */
    width: 100%; /* Make the link take full width of the navbar */
    text-shadow: none; /* No shadow by default */
}

/* Hover and Active Effects (With Scale) */
.nav-list a.active,
.nav-list a:hover {
    color: #03b15a; /* Change text color */
    transform: translateX(20px) scale(1.1); /* Slide and scale up */
    text-shadow: 0 0 8px rgba(3, 177, 90, 0.8), 0 0 15px rgba(3, 177, 90, 0.6), 0 0 30px rgba(3, 177, 90, 0.4); /* Neon text glow */
}

/* About Me */
.nav-list .about.active,
.nav-list .about:hover {
    color: #ff5733; /* Text color when active/hovered */
    transform: translateX(20px) scale(1.1); /* Slide and scale up */
    text-shadow: 0 0 8px rgba(255, 87, 51, 0.8), 0 0 15px rgba(255, 87, 51, 0.6), 0 0 30px rgba(255, 87, 51, 0.4); /* Neon text glow */
}

/* Skills */
.nav-list .skills.active,
.nav-list .skills:hover {    
    color: #f1c40f; /* Text color when active/hovered */
    transform: translateX(20px) scale(1.1); /* Slide and scale up */
    text-shadow: 0 0 8px rgba(241, 196, 15, 0.8), 0 0 15px rgba(241, 196, 15, 0.6), 0 0 30px rgba(241, 196, 15, 0.4); /* Neon text glow */
}

/* Projects */
.nav-list .projects.active,
.nav-list .projects:hover {
    color: #03b15a; /* Text color when active/hovered */
    transform: translateX(20px) scale(1.1); /* Slide and scale up */
    text-shadow: 0 0 8px rgba(3, 177, 90, 0.8), 0 0 15px rgba(3, 177, 90, 0.6), 0 0 30px rgba(3, 177, 90, 0.4); /* Neon text glow */
}

/* Contact */
.nav-list .contact.active,
.nav-list .contact:hover {
    color: #3498db; /* Text color when active/hovered */
    transform: translateX(20px) scale(1.1); /* Slide and scale up */
    text-shadow: 0 0 8px rgba(52, 152, 219, 0.8), 0 0 15px rgba(52, 152, 219, 0.6), 0 0 30px rgba(52, 152, 219, 0.4); /* Neon text glow */
}



/* Responsive Design for Navbar */
@media (max-width: 768px) {
    .navbar {
        justify-content: flex-start; /* Adjust for smaller screens */
        padding: 10px 15px;
        border-radius: 0; /* Remove rounded corners for mobile */
    }

    .nav-list {
        justify-content: flex-start;
        width: 100%;
    }

    .nav-list li {
        margin-left: 10px;
    }

    .navbar a {
        font-size: 0.8rem;
    }
}


/* Fade-In Animation */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.intro-details {
    animation: fadeInUp 1.5s ease-in-out;
}

.intro-details h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #03b15a; /* Teal for section titles */
    text-align: center;
}

.intro-details p {
    font-size: 18px;
    line-height: 1.6;
    color: #dcdcdc;
}



/* Skills Section */
.skills-section {
    padding: 50px 20px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 200px; /* Adjust this value to move it further to the right */
    scale: .904;
}

/* Skills Container */
.skills-container {
    background: #ffffff0e;
    backdrop-filter: blur(50px);
    border-radius: 25px;
    padding: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    max-width: 1200px;
    width: 100%;
}

/* Skills Header Card */
.skills-header-card {
    text-align: center;
    margin-bottom: 20px;
}

.skills-header-card h1 {
    font-size: 36px;
    color: #03b15a;
    margin-bottom: 10px;
}

.skills-header-card p {
    font-size: 1rem;
    color: grey;
}

/* Skills Cards */
.skills-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2x2 grid layout */
    grid-gap: 20px; /* Space between cards */
}


/* Skill Category Card */
.skill-category-card {
    background: #ffffff0c;
    backdrop-filter: blur(50px);

    border-radius: 25px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    max-height: 60px; /* Initial height */
}

.skill-category-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
}

.skill-category-card h2 {
    font-size: 1.8rem;
    color: #00bcd4;
    margin: 0;
    text-align: center;
}

/* Expanded State */
.skill-category-card.expanded {
    max-height: 500px; /* Expand height to fit the content */
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.skill-category-card.expanded .skills-details {
    opacity: 1;
    max-height: 1000px; /* Allow for the content to expand */
    padding-top: 20px;
    transition: max-height 0.3s ease, opacity 0.3s ease;
}

.skill-category-card .skills-details {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
}

.skills-details .skill {
    margin-bottom: 10px;
}

.skills-details .skill h3 {
    font-size: 1.5rem;
    margin: 0;
    color: #ffffff;
    text-align: center;
}

.skills-details .skill p {
    font-size: 1.2rem;
    margin: 5px 0 0;
    color: #dcdcdc;
    text-align: center;
}

@media (max-width: 768px) {
    .skills-cards {
        grid-template-columns: 1fr; /* Stack cards vertically on smaller screens */
    }
}








/* Typing Animation */
.typing {
    border-right: 2px solid #00bcd4; /* Cursor effect */
    white-space: nowrap;
    overflow: hidden;
    animation: typing 3s steps(40, end), blink-caret 0.75s step-end infinite;
}

@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes blink-caret {
    from, to {
        border-color: transparent;
    }
    50% {
        border-color: #00bcd4; /* Blinking cursor color */
    }
}



/* About Section */
.about-section {
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 200px;
    vertical-align: center;
    scale: .9;
    color: #ffffff;
}

/* About Container */
.about-container {
    display: flex;
    align-items: stretch; /* Ensure both children have equal height */
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    background: #ffffff0e;
    backdrop-filter: blur(50px);
    border-radius: 25px;
    padding: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}
  
  .about-image {
    flex: 0 0 40%; /* Make the image container take up 40% of the width */
    display: flex; /* Enable flexbox for centering the image */
    align-items: center; /* Center the image vertically */
    justify-content: center; /* Center the image horizontally */
    min-height: 300px; /* Set a minimum height */
    padding-right: 20px; /* Padding between the image and the content */
  }
  
  .about-image img {
    border-radius: 25px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Shadow for depth */
    max-width: 100%;
    height: 100%; /* Ensure the image fills the container */
    object-fit: cover; /* Maintain aspect ratio and cover the area */
  }


  /* Responsive Design */
  @media (max-width: 768px) {
    .about-container {
      flex-direction: column;
      text-align: center;
    }
  
    .about-image img {
      margin-bottom: 20px;
    }
  
    .terminal-window {
      width: 100%;
    }
  
    .terminal-body h1 {
      font-size: 2rem;
    }
  
    .terminal-body h2 {
      font-size: 1.5rem;
    }
  }
  
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about-container {
      flex-direction: column;
      text-align: center;
    }
  
    .about-image img {
      margin-bottom: 20px;
    }
  
    .terminal-window {
      width: 100%;
    }
  
    .terminal-body h1 {
      font-size: 2rem;
    }
  
    .terminal-body h2 {
      font-size: 1.5rem;
    }
  }
  




  /* terminal window effect*/
  
  .terminal-window {
    background-color: #2b2b2b;
    border-radius: 25px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    flex: 0 0 60%; /* Make the content container take up 60% of the width */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align content to start from the top */
    min-height: 300px; /* Set a minimum height */
  }

  .terminal-body {
    font-family: 'Source Code Pro', monospace;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Content starts at the top */
    align-items: flex-start; /* Align text to the left */
  }

  /* Typing effect */
  .terminal-body p {
    font-family: 'Source Code Pro', monospace;
    font-size: 1.2rem;
    line-height: 1.6;
    max-width: 600px;
    margin-top: 20px;
  }


  .terminal-header {
    font-family: 'Source Code Pro', monospace;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  
  .terminal-buttons {
    display: flex;
  }
  
  .terminal-buttons span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }


  .terminal-body h1,
  .terminal-body h2 {
    margin: 0;
  }
  
  .terminal-body h2 {
    margin-bottom: 20px;
  }
  
  .terminal-body .highlight {
    font-family: 'Source Code Pro', monospace;
    color: #00b4d8; /* Highlight color to make the name stand out */
    font-weight: bold;
  }

  
  .terminal-buttons .red {
    background-color: #ff5f56;
  }
  
  .terminal-buttons .yellow {
    background-color: #ffbd2e;
  }
  
  .terminal-buttons .green {
    background-color: #27c93f;
  }
  
    /* terminal window effect*/


/* Contact Me Section */

/* Contact Section */
.contact-section {
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 200px; /* Adjust this value to move it further to the right */
    scale: .904;
}

.contact-container {
    width: 100%;
    max-width: 1200px; /* Set a max width for the container */
    background-color: #ffffff0e;
    backdrop-filter: blur(50px);
    border-radius: 25px;
    padding: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.contact-container h1 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #03b15a; /* Teal color for section title */
}

/* Container for smaller cards */
.contact-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-gap: 20px; /* Space between cards */
}

/* Individual Contact Cards */
.contact-card {
    background: #ffffff0c;
    border-radius: 25px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    color: #ffffff;
    text-align: center;
}

.contact-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.contact-card h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #00bcd4; /* Teal for the card titles */
}

.contact-card p,
.contact-card a {
    font-size: 1.2rem;
    margin: 0;
    color: #ffffff;
    text-decoration: none;
}

.contact-card a:hover {
    color: #00b4d8;
}

@media (max-width: 768px) {
    .contact-cards {
        grid-template-columns: 1fr; /* Stacks the cards vertically on smaller screens */
    }
}




/* Projects Section */
.projects-section {
    padding: 50px 20px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 200px; /* Adjust this value to move it further to the right */
    scale: .904;
}

.projects-container {
    background: #ffffff0e;
    backdrop-filter: blur(50px);
    border-radius: 25px;
    padding: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    max-width: 1200px;
    width: 100%;
    text-align: center;
}

.projects-container h1 {
    font-size: 36px;
    color: #03b15a;
    margin-bottom: 20px;
}

.projects-container p {
    font-size: 1.2rem;
    margin: 5px 0 0;
    color: grey;
    text-align: center;
    margin-bottom: 20px;

}



/* Projects Grid */
.projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    color: #dcdcdc ;
}

/* Project Card */
.project-card {
    background: #ffffff0c;
    backdrop-filter: blur(50px);
    border-radius: 25px;
    padding: 20px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5);
}

.project-card h2 {
    font-size: 24px;
    color: #00bcd4;
    margin-bottom: 10px;
}

.project-card p {
    font-size: 1.2rem;
    color: #dcdcdc;
    margin-bottom: 15px;
}


/* Project Modal */
.project-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.project-modal-content {background-image: url("bg.jpg"); /* Relative path */
    background-size: cover; /* Make the background cover the entire area */
    background-repeat: no-repeat; /* Prevent repeating */
    background-position: center; /* Center the background image */
    overflow-x: hidden;
    
    border-radius:25px;
    padding: 30px;
    width: 80%;
    max-width: 800px;
    text-align: left;
    color: #ffffff;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    cursor: pointer;
}

.modal-image {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    margin-bottom: 20px;
}

.project-modal-content h2 {
    font-size: 28px;
    margin-bottom: 10px;
    color: #00bcd4;
}

.project-modal-content p {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #dcdcdc;
}

.project-modal-content ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 15px;
    color: #dcdcdc;
}

.project-modal-content ul li {
    margin-bottom: 10px;
}

.project-modal-content a {
    color: #00bcd4;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.project-modal-content a:hover {
    color: #00b4d8;
}

@media (max-width: 768px) {
    .projects-grid {
        grid-template-columns: 1fr;
    }
}


/* Custom Scrollbar for WebKit browsers */
::-webkit-scrollbar {
    width: 10px; /* Width of the vertical scrollbar */
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1); /* Scrollbar track (background) */
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #03b15a; /* Scrollbar thumb (color of the draggable part) */
    border-radius: 10px; /* Rounded corners for the thumb */
    border: 2px solid transparent; /* Space around thumb */
    background-clip: padding-box; /* Clip the thumb inside the scrollbar */
    border-end-end-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #00bcd4; /* Change color on hover */
}
